<template>
  <div class="privacy-main">
    <Header />
    <div class="privacy-top">
      <div class="title">AI得贤招聘官隐私声明</div>
      <div>最后更新时间：2022年3月9号</div>
    </div>
    <div class="privacy">
      <!-- <p>AI得贤招聘官隐私声明</p> -->
      <h3>我们的隐私声明</h3>
      <p>
        AI得贤招聘官（“我们”）非常重视保护隐私和个人信息的义务。请仔细阅读本隐私声明（通知），因为它列出了与我们如何处理您的个人信息有关的重要信息。
      </p>
      <p>
        本隐私政策阐释我们所收集的各种个人信息，及我们如何处理及保护该等数据。如果您不同意按照本隐私声明所述方式处理您的个人信息，请不要在收到请求时提供您的信息，并停止使用本网页/APP/微信小程序。继续使用本网页/APP/微信小程序即表示您确认我们在本隐私声明中所述的有关您个人信息的规定。
        我们时刻将您的个人信息保密处理，并确保有关收集、使用、披露、转移、保密、查阅、更正及删除个人信息的政策及常规，均符合中国法律规定。
      </p>

      <h4>1. AI得贤招聘官是做什么的？我们为什么收集您的个人信息？</h4>

      <p>
        AI得贤招聘官是通过基于云的视频面试、评估、日程安排和对话式AI服务，帮助全球企业在现代人才市场中获得竞争优势。我们的客户（“雇主”）可以使用我们的软件和SaaS服务来安排和进行视频面试，或者可以将我们的视频面试与我们的评估工具相结合，该工具涉及使用预测的、经过验证的职业科学和人工智能，以增强客户在招聘过程中的人工决策。
      </p>

      <h4>
        2. AI得贤招聘官通过服务收集哪些信息？
      </h4>

      <p>
        我们将收集和处理您的个人信息，以下概述了我们通常通过服务收集的个人信息类型：
      </p>
      <p class="dot">
        您自愿提供的信息
      </p>
      <p>
        在使用服务时，您可能需要提供有关您自己的某些个人信息——例如：姓名、联系方式、用户名和密码、图像捕捉/音频捕捉（例如在活动中拍摄的照片、视频、音频和闭路电视录像），以及雇主要求的其他信息。您可以通过服务完成工作面试，其中可以包括工作经历、技能、经验、能力、工作风格和其他对问题的回答等信息，以及通过各种类型的媒体提交的信息，包括视频/音频面试、编程考试、训练练习等。
      </p>

      <p class="dot">
        我们自动收集的信息
      </p>
      <p>
        有时，我们会由于跟雇主系统打通自动从第三方来源接收有关您的个人信息——例如姓名、联系方式、教育经历、工作经历等。
      </p>
      <p class="dot">
        第三方提供的信息
      </p>
      <p>
        雇主在与我们的通信中提供或补充您的个人信息，以确保顺利开展面试安排。
      </p>

      <h4>3. AI得贤招聘官如何使用这些信息？</h4>
      <p>
        我们可能会以多种方式使用您的个人信息来运营网站及其服务，包括：
      </p>
      <p class="dot">
        就服务的管理目的、用户服务目的或您发布到服务的内容与您联系；
      </p>
      <p class="dot">发送管理信息，例如与产品、服务或政策变更相关的通知；</p>
      <p class="dot">
        将您的个人信息完全脱敏后用于内部分析和研究，以帮助我们改进我们的产品和服务；
      </p>
      <p class="dot">
        了解和分析我们用户的使用趋势、兴趣和偏好，改进网站和服务的工作方式和外观，并创建新的特性和功能；
      </p>
      <p class="dot">预防、检测、减轻和调查欺诈或非法活动。</p>

      <h4>4. AI得贤招聘官向谁共享这些信息？</h4>
      <p>
        我们可能会向以下类别的人员披露您的个人信息：
      </p>
      <p class="dot">
        向雇主分享您的个人信息，并按照法律规定范围内的处理事务。
      </p>
      <p class="dot">
        向任何主管执法机构、监管机构、政府机构、法院或我们认为有必要披露的其他第三方(i) 作为适用法律或法规的事项，(ii) 行使、确立或捍卫我们的合法权利，或 ( iii) 保护您或任何其他人的切身利益；
      </p>
      <p class="dot">在您同意披露的情况下向任何其他人披露。</p>
      <p class="dot">您自己。</p>

      <h4>5. AI得贤招聘官将保留这些信息多长时间？</h4>
      <p>
        我们会按照以下方式保留您的个人信息：
      </p>
      <p class="dot">
        我们将按照雇主的指示代表雇主保留您的个人信息。我们被指示保留您的个人信息的时间因雇主而异，通常是我们被指示保留相关评估的时间，但时间不会超过6个月。
      </p>
      <p class="dot">
        您完成视频面试/评估的时间已超过六个月，您可以通知我们关闭您自己所有个人信息的呈现，也可以要求删除自己的相关资料。
        如果与调查或争议有关，我们不会删除您的个人信息。它将继续存储，直到这些问题得到完全解决。
      </p>

      <h4>
        6. AI得贤招聘官如何确保您的个人信息安全？
      </h4>
      <p>
        我们采取适当的技术和企业措施，以保护您向我们提供的个人信息，防止意外或非法销毁、丢失、更改、披露或存取您的个人信息。
        如若不幸发生个人信息安全事件，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      </p>

      <h4>
        7. 您的义务
      </h4>
      <p>
        您承诺使用“AI 得贤招聘官”仅用于自身找工作和学习培训等职业相关行为。
        您承诺在接受“AI得贤招聘官”面试服务时，遵守法律法规、社会主义制度、国家利益、公民合法权益、公开秩序、社会道德风尚，并保证传输的内容和陈述信息真实合法有效。
        您承诺提供本人真实、正确、最新及完整的资料，包括但不限于姓名、联系电话、邮箱、通讯地址、学历、工作经历等。您保证如实描述和及时更新，保持其真实性及有效性，否则承担因信息不真实、不合法、不准确所造成的任何损失。
      </p>

      <h4>
        8. 您的权利
      </h4>
      <p>
        在适用法律和法规允许范围内，您拥有针对我们持有的您的个人信息的相关法定权利（在适用法律和法规允许范围内）：
      </p>

      <p class="dot">
        查询：您有权询问我们是否正在处理您的信息，如果我们正在处理，您可以请求访问您的个人信息。这使您能够收到我们持有的关于您的个人信息的副本以及有关它的某些其他信息。
      </p>
      <p class="dot">
        更正：您有权要求更正我们持有的关于您的任何不完整或不准确的个人信息。
      </p>
      <p class="dot">
        删除：在某些情况下，您有权要求我们删除或移除个人信息，例如，您完成视频面试/评估的时间已超过六个月。在某些例外情况下，我们可能会拒绝删除请求，例如，在遵守法律或与索赔相关的情况下需要个人数据。
      </p>
      <p class="dot">
        异议：如果我们基于合法利益（或第三方利益）处理您的个人信息，您可以对此提出异议。但是，我们可能有权根据我们的合法利益或在与法律索赔相关的情况下继续处理您的信息。
      </p>
      <p>
        如您需要行使法律法规规定的其他法定权利，可通过本隐私政策第10条约定的方式提出请求，我们会努力尽快处理您的请求。
      </p>

      <h4>
        9. 其他
      </h4>
      <p>9.1 链接</p>
      <p>
        我们的网站可能包含指向其他“非AI得贤招聘官”网站的超链接。我们不能管制这些第三方网站或这些网站上的任何内容、安全或隐私政策和做法，也不对其承担任何责任。AI得贤招聘官鼓励并强烈建议您阅读这些网站的隐私政策，以确定他们如何保护和使用个人信息。
      </p>
      <p>9.2 未成年人信息的保护</p>
      <p>
        本应用程序仅面向成年人以及以自己的劳动收入为主要生活来源的十六周岁以上的未成年人用户提供服务。如果您并非前述用户，尤其是未满十四周岁的儿童，请不要使用本应用程序，也不要通过本应用程序向我们提供您的个人信息。
      </p>
      <p>9.3 本隐私政策更新</p>
      <p>
        我们的服务以及技术不断跟随您以及其他用户的需求更新和优化，因此在新的服务和业务流程变更时，我们将根据国家法律法规变化及我们的运营需要对本隐私政策不时进行修改，并在本应用程序显著位置以公告的方式予以公布，并代替原来的隐私政策。您可在本应用程序随时查阅最新隐私政策，若您在本隐私政策公告变更后继续使用服务的，表示您已充分阅读、理解并接受修改后的隐私政策，也将遵循修改后的隐私政策使用我们的服务。
      </p>
      <p>9.4 本隐私政策并不限制法律规定所保障您享有的权利。</p>
      <p>9.5 本隐私政策受中国法律管辖，并按中国法律予以解释。</p>

      <h4>
        10.如何联系我们？
      </h4>
      <p>
        如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，您可以通过以下方式与我们联系：
      </p>
      <p>服务邮箱：service@airecruitas.com</p>
    </div>
  </div>
</template>
<script>
import Header from '@/components/menu'
export default {
  data() {
    return {}
  },
  components: { Header },
}
</script>
<style lang="scss" scoped>
.privacy-main {
  position: relative;
  // padding-top: 60px;
  .privacy-top {
    background: #002343;
    color: #ffffff;
    text-align: center;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    .title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .privacy {
    margin: 0 20px;
    h3 {
      margin-bottom: 20px;
    }
    h4 {
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 10px;
      line-height: 28px;
    }
    .dot {
      padding-left: 10px;
      display: flex;
      position: relative;
    }
    .dot::before {
      content: ' ';
      display: block;
      width: 5px;
      height: 5px;
      min-width: 5px;
      min-height: 5px;
      max-width: 5px;
      max-height: 5px;
      border-radius: 50%;
      background: #333333;
      position: relative;
      top: 10px;
      right: 5px;
    }
  }
}
</style>
